import gsap from 'gsap';
import axios from 'axios';

import $ from '../core/Dom';
import Components from '../core/Components';

export default (el, props) => {
    const $el = $(el);
    const { loadUrl } = props;

    const $filterForm = $el.find('[data-filter-form]');
    const $filterTarget = $el.find('[data-filter-target]');
    const $filterClear = $el.find('[data-filter-clear-button]');

    let hasUpdatedHistory = false;

    const init = () => {
        if ($filterForm.length > 0) {
            $filterForm.on('submit', onFilterSubmit);
            $filterForm.on('click', 'input[type="checkbox"]', onCheckboxClick);

            if (window.history) {
                window.history.replaceState({ reloadBack: true }, document.title);
            }

            $filterClear.on('click', onFilterClear);
        }
    };

    const destroy = () => {
        $el.off('click');
    };

    const onFilterClear = () => {
        $filterForm.find('input[type=checkbox]')
            .nodes
            .forEach(chk => chk.checked = false);
        onFilterSubmit();
    };

    const onCheckboxClick = () => {
        onFilterSubmit();
    };

    const onFilterSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        const formData = new FormData($filterForm.get(0));
        const searchParams = new URLSearchParams(formData);
        const queryString = searchParams.toString();

        if (queryString !== '') {
            $filterClear.css({
                display: 'flex'
            });
            gsap.to($filterClear.get(0), {
                duration: 0.3,
                opacity: 1
            });
        } else {
            gsap.to($filterClear.get(0), {
                duration: 0.2,
                opacity: 0,
                onComplete: () => {
                    $filterClear.css({ display: '' });
                }
            });
        }

        axios({
            url: loadUrl,
            method: 'get',
            params: searchParams,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'text/html'
            }
        })
            .then(res => {
                const {
                    status,
                    data
                } = res || {};

                if (status === 200 && data) {
                    addContent(data);

                    if (window.history) {
                        if (hasUpdatedHistory) {
                            window.history.replaceState({ reloadBack: true }, document.title, loadUrl + (queryString !== '' ? '?' + queryString : ''));
                        } else {
                            window.history.pushState({ reloadBack: true }, document.title, loadUrl + (queryString !== '' ? '?' + queryString : ''));
                        }

                        hasUpdatedHistory = true;
                    }

                } else {
                    // Do something else
                }
            })
            .catch(error => {
                console.error(error);
            });

    };

    const addContent = text => {
        const $html = $(text);
        const $newContent = $html.find('[data-filter-target]');
        $newContent.find('noscript')
            .remove();

        $filterTarget.empty();
        $filterTarget.append($newContent.get(0).children);

        Components.init($filterTarget);

        gsap.set($filterTarget.get(0), { opacity: 0 });

        gsap.to($filterTarget.get(0), {
            opacity: 1,
            duration: 0.6,
            delay: 0.4
        });
    };

    return {
        init,
        destroy
    };

};
