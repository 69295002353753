import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import { loadFlickity } from '../lib/async-bundles';
import { MODAL_SHOW } from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const { zoomUrl } = props;

    const $imagesSlider = $el.find('[data-images-wrapper]');
    const $images = $el.find('[data-product-image]');

    const $thumbsSlider = $el.find('[data-thumbs-wrapper]');
    const $thumbsSlides = $el.find('[data-thumbs-item]');

    const $prevBtn = $el.find('[data-prev-thumbs-button]');
    const $nextBtn = $el.find('[data-next-thumbs-button]');
    const $zoomBtn = $el.find('[data-zoom-button]');

    let Flickity;
    let thumbsSlideshow;
    let imagesSlideshow;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;

            initFlickity();
            
            if ($thumbsSlides.length > 3) {
                $prevBtn.on('click', onPrevClick);
                $nextBtn.on('click', onNextClick);
            }
        });

        $thumbsSlides.on('click', onThumbClick);
        $zoomBtn.on('click', onZoomClick);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        if (thumbsSlideshow) {
            destroyFlickity();
        }

        $thumbsSlides.off('click', onThumbClick);
        Viewport.off('resize', onResize);
    };

    const onZoomClick = () => {
        Dispatch.emit(MODAL_SHOW, { type: 'imagesSlideshow', loadUrl: zoomUrl });
    };
    
    const showImage = num => {
        if (imagesSlideshow) {
            imagesSlideshow.selectCell(num - 1);
        }
    };

    const onThumbClick = e => {
        const $triggerTarget = $(e.triggerTarget);
        showImage($triggerTarget.data('thumbs-item'));
    };

    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');

        // Thumbs flickity
        if ($thumbsSlides.length > 3) {
            $thumbsSlides.each(item => {
                const $item = $(item);
                $item.css({
                    height: $item.height(),
                    display: 'block'
                });
            });

            $thumbsSlider.css({
                height: `${$thumbsSlider.height()}px`
            });

            thumbsSlideshow = new Flickity($thumbsSlider.get(0), {
                pageDots: false,
                prevNextButtons: false,
                setGallerySize: false,
                resize: false,
                wrapAround: false,
                freeScroll: false,
                groupCells: 3,
                contain: true,
                accessibility: true
            });

            thumbsSlideshow.on('dragStart', () => thumbsSlideshow.slider.childNodes.forEach(slide => slide.style.pointerEvents = 'none'));
            thumbsSlideshow.on('dragEnd', () => thumbsSlideshow.slider.childNodes.forEach(slide => slide.style.pointerEvents = 'all'));
        }
        
        // Images flickity
        if ($images.length > 1) {
            $images.removeClass('hidden');
            $images.each(item => {
                const $item = $(item);
                $item.css({
                    height: $item.height(),
                    display: 'block'
                });
            });
    
            $imagesSlider.css({
                height: `${$imagesSlider.height()}px`
            });
    
            imagesSlideshow = new Flickity($imagesSlider.get(0), {
                pageDots: false,
                prevNextButtons: false,
                setGallerySize: false,
                resize: false,
                wrapAround: true,
                selectedAttraction: 0.15,
                friction: 1,
                freeScroll: false,
                contain: true,
                accessibility: true
            });
    
            imagesSlideshow.on('dragStart', () => thumbsSlideshow.slider.childNodes.forEach(slide => slide.style.pointerEvents = 'none'));
            imagesSlideshow.on('dragEnd', () => thumbsSlideshow.slider.childNodes.forEach(slide => slide.style.pointerEvents = 'all'));
        }
    };

    const destroyFlickity = () => {
        if (thumbsSlideshow) {
            thumbsSlideshow.destroy();
            thumbsSlideshow = null;

            $thumbsSlider.attr('style', '');
        }

        if (imagesSlideshow) {
            imagesSlideshow.destroy();
            imagesSlideshow = null;

            $imagesSlider.attr('style', '');
        }

        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        if ($images.length > 1) {
            initFlickity();
        } else {
            destroyFlickity();
        }
    };

    const onPrevClick = e => {
        e.preventDefault();
        if (thumbsSlideshow) {
            thumbsSlideshow.previous();
        }
    };

    const onNextClick = e => {
        e.preventDefault();
        if (thumbsSlideshow) {
            thumbsSlideshow.next();
        }
    };

    return {
        init,
        destroy
    };

};
