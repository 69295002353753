import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import Modal from './lib/Modal';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    Modal.init();
    lazySizes.init();

    // Anchor links
    $('body').on('click', 'a[href^="#"]', e => {
        if (document.documentElement.classList.contains('plaintext')) {
            return;
        }
        
        const targetId = e.triggerTarget.getAttribute('href').replace('#', '');
        const target = document.getElementById(targetId);
        if (!target) {
            return;
        }
        
        e.preventDefault();
        
        // Get the top scroll margin
        const scrollMargin = parseInt((window.getComputedStyle(target).scrollMarginTop || '0px').replace('px', ''), 10);
        gsap.to(window, { scrollTo: Math.min(target.offsetTop - scrollMargin, document.documentElement.scrollHeight - Viewport.height), duration: 0.75, ease: 'Cubic.easeInOut' });
    });
    
    window.addEventListener('popstate', e => {
        const { state } = e;

        if (state && state.reloadBack) {
            window.location.reload();
        }
    });

};

require('doc-ready')(init);
