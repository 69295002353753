import gsap from 'gsap';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT } from '../lib/events';

export default (button, props) => {

    const $button = $(button);
    let isLocked = true;
    let screenLock = null;

    const isScreenLockSupported = () => {
        return ('wakeLock' in navigator);
    };

    async function getScreenLock() {
        if (isScreenLockSupported()) {
            try {
                screenLock = await navigator.wakeLock.request('screen');
                //console.log('Lock aquired 🎈');
                $button.attr('aria-pressed', 'true');
            } catch (err) {
                //console.log(err.name, err.message);
                $button.attr('aria-pressed', 'false');
            }
            return screenLock;
        }
    }

    function release() {
        if (typeof screenLock !== 'undefined' && screenLock != null) {
            screenLock.release()
                .then(() => {
                    //console.log('Lock released 🎈');
                    screenLock = null;
                    $button.attr('aria-pressed', 'false');
                });
        }
    }

    async function onClick() {
        if (screenLock) {
            release();
        } else {
            screenLock = await getScreenLock();
        }

        $button.attr('aria-pressed', screenLock ? 'true' : 'false');
    }

    async function init() {
        if (!isScreenLockSupported()) {
            $button.css({ opacity: 0.5 }).attr('disabled', 'disabled');
        }

        screenLock = await getScreenLock();

        button.addEventListener('click', onClick);

        Dispatch.emit(COMPONENT_INIT);
    }

    const destroy = () => {
        button.removeEventListener('click', onClick);
    };

    return {
        init,
        destroy
    };

};
