import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import { MODAL_SHOW } from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const { videoUrl } = props;
    
    const init = () => {
        $el.on('click', 'a', onPlayClick);
    };

    const destroy = () => {
        $el.off('click', 'a', onPlayClick);
    };

    const onPlayClick = e => {
        e.preventDefault();
        Dispatch.emit(MODAL_SHOW, { type: 'video', loadUrl: videoUrl });
    };
    
    return {
        init,
        destroy
    };

};
