import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from '../lib/async-bundles';

export default (el, props) => {
    const SLIDER_SELECTOR = '[data-slides-wrapper]';
    const SLIDE_SELECTOR = '[data-slide-item]';

    const $el = $(el);
    const { disableBreakpoint } = props;

    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);

    const $prevBtn = $el.find('[data-prev-slide-button]');
    const $nextBtn = $el.find('[data-next-slide-button]');

    let Flickity;
    let slideshow;
    let lastW = 0;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;

            if (Viewport.breakpoint.size < disableBreakpoint) {
                initFlickity();
            }

            lastW = Viewport.width;
            Viewport.on('resize', onResize);
            $prevBtn.on('click', onPrevClick);
            $nextBtn.on('click', onNextClick);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };

    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slides.each(item => {
            const $item = $(item);
            $item.css({
                height: $item.height(),
                display: 'block'
            });
        });

        $slider.css({
            height: `${$slider.height()}px`
        });

        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: false,
            freeScroll: true,
            groupCells: true,
            cellAlign: 'left',
            contain: true,
            accessibility: false
        });

        slideshow.on('dragStart', () => slideshow.slider.childNodes.forEach(slide => {
            slide.style.pointerEvents = 'none';
        }));

        slideshow.on('dragEnd', () => slideshow.slider.childNodes.forEach(slide => {
            slide.style.pointerEvents = 'all';
        }));

        slideshow.on('select', function (index) {
            checkButtons(index, slideshow.slides.length);
        });

        checkButtons(slideshow.selectedIndex, slideshow.slides.length);
    };

    const checkButtons = (index, count) => {
        $prevBtn.attr('disabled', null);
        $nextBtn.attr('disabled', null);
        
        if (index === 0) {
            $prevBtn.attr('disabled', 'disabled');
        }
        
        if (index >= count-1) {
            $nextBtn.attr('disabled', 'disabled');
        }
    };

    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }

        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');

        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        if (lastW !== Viewport.width) {
            if (Viewport.breakpoint.size < disableBreakpoint) {
                initFlickity();
            } else {
                destroyFlickity();
            }

            lastW = Viewport.width;
        }
    };

    const onPrevClick = e => {
        if (slideshow) {
            slideshow.previous();
        }
    };

    const onNextClick = e => {
        if (slideshow) {
            slideshow.next();
        }
    };

    return {
        init,
        destroy
    };

};
