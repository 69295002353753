import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from '../lib/async-bundles';

export default (el, props) => {

    const $el = $(el);

    const $images = $el.find('[data-slide]');

    let Flickity;
    let imagesSlideshow;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;

            if ($images.length > 1) {
                initFlickity();
            }
        });

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        if (imagesSlideshow) {
            destroyFlickity();
        }

        Viewport.off('resize', onResize);
    };

    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');
        
        // Images flickity
        if ($images.length > 1) {
            $images.removeClass('hidden');
            
            $images.each(item => {
                const $item = $(item);
                $item.css({
                    height: $item.height(),
                    display: 'block'
                });
            });
    
            $el.css({
                height: `${$el.height()}px`
            });
    
            imagesSlideshow = new Flickity($el.get(0), {
                pageDots: false,
                prevNextButtons: false,
                setGallerySize: false,
                resize: false,
                wrapAround: true,
                selectedAttraction: 0.15,
                friction: 1,
                freeScroll: false,
                contain: true,
                accessibility: true
            });
    
            imagesSlideshow.on('dragStart', () => imagesSlideshow.slider.childNodes.forEach(slide => slide.style.pointerEvents = 'none'));
            imagesSlideshow.on('dragEnd', () => imagesSlideshow.slider.childNodes.forEach(slide => slide.style.pointerEvents = 'all'));
        }
    };

    const destroyFlickity = () => {
        if (imagesSlideshow) {
            imagesSlideshow.destroy();
            imagesSlideshow = null;

            $el.attr('style', '');
        }

        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        if ($images.length > 1) {
            initFlickity();
        } else {
            destroyFlickity();
        }
    };
    
    return {
        init,
        destroy
    };

};
