import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const {
        closeText,
        openText
    } = props;

    const $el = $(el);
    const $body = $('body');
    const $bar = $el.find('[data-header-bar]');
    const $menu = $el.find('[data-menu]');
    const $menuBtn = $el.find('[data-menu-toggle]');
    const $langBtn = $el.find('[data-lang-toggle]');
    const $langCloseBtn = $el.find('[data-language-selector-close-btn]');
    const $langSelector = $el.find('[data-language-selector]');

    let isMenuOpen = false;
    let isLangOpen = false;

    let isHidden = false;
    let isLocked = false;
    let hiddenThreshold = 30;
    let currentScrollTop = Viewport.scrollTop;
    let breakpoint = Viewport.breakpoint.size;

    const init = () => {
        $menuBtn
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    onToggleMenu();
                }
            })
            .on('click', e => {
                e.preventDefault();
                onToggleMenu();
            })
            .get(0)
            .removeAttribute('href');

        $langBtn
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    onToggleLang();
                }
            })
            .on('click', e => {
                e.preventDefault();
                onToggleLang();
            })
            .get(0)
            .removeAttribute('href');

        $langCloseBtn.on('click', e => {
            e.preventDefault();
            onToggleLang();
        });
        
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $body.on('keyup', onBodyKeyUp);
        $el.on('focusin', 'a,button', onInnerFocus);

        $bar.removeClass('js:opacity-0');

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (window.scrollY > hiddenThreshold) {
                hide(true);
            }
        });

    };

    const destroy = () => {
        $menuBtn.off('click');
        $menuBtn.off('keydown');
        $langBtn.off('click');
        $langBtn.off('keydown');
        
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        
    };
    
    const onBodyKeyUp = e => {
        if (!isMenuOpen && !isLangOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            if (isLangOpen) {
                onToggleLang();
            } else if (isMenuOpen) {
                onToggleMenu();
            }
        }
    };    

    const onBreakpoint = () => {
        breakpoint = Viewport.breakpoint.size;
    };

    const onResize = () => {
        hiddenThreshold = getThreshold();

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    const onInnerFocus = e => {
        show();
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }

        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }

        currentScrollTop = scrollTop;
    };
    

    /**
     * Scroll behaviour
     */
    const hide = force => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $el.addClass('is-forced');

            setTimeout(() => {
                $el.removeClass('is-forced');
            }, 600);
        }

        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $el.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $el.removeClass('is-locked');
    };

    const getThreshold = () => {
        if (breakpoint < 1280) {
            return 88;
        }  

        return 120;
    };    
    
    const onToggleMenu = () => {
        if (isMenuOpen) {
            $bar.removeClass('theme-green', 'antialiased');
            gsap.to($menu.get(0), {
                opacity: 0,
                duration: 0.2,
                ease: 'sine.out',
                onComplete: () => {
                    $menu.addClass('hidden');
                }
            });
            
            unlock();
        } else {
            $menu.removeClass('hidden')
                .css({ opacity: 0 });
            $bar.addClass('theme-green', 'antialiased');

            gsap.to($menu.get(0), {
                opacity: 1,
                duration: 0.2,
                ease: 'sine.out'
            });
            
            lock();
        }

        isMenuOpen = !isMenuOpen;
        $menuBtn.attr('aria-expanded', isMenuOpen ? 'true' : 'false');
        $menuBtn.text(isMenuOpen ? closeText : openText);
    };

    const onToggleLang = () => {
        if (isLangOpen) {
            gsap.to($langSelector.get(0), {
                opacity: 0,
                duration: 0.2,
                ease: 'sine.out',
                onComplete: () => {
                    $langSelector.addClass('hidden');
                }
            });
            
            if (!isMenuOpen) {
                unlock();
            }
        } else {
            $langSelector.removeClass('hidden')
                .css({ opacity: 0 });

            gsap.to($langSelector.get(0), {
                opacity: 1,
                duration: 0.2,
                ease: 'sine.out'
            });
            
            lock();
        }

        isLangOpen = !isLangOpen;
        $langBtn.attr('aria-expanded', isLangOpen ? 'true' : 'false');
    };

    return {
        init,
        destroy
    };

};
